.sun{
  // background: radial-gradient(50% 50% at 50% 50%, #FFFFF5 10.94%, rgba(255, 168, 0, 0) 100%);
  // width: 424px;
  // height: 424px;
  // box-shadow: 0 0 20px #ffb300, 0 0 120px #ffc107, 0 0 80px #ffca28, 0 0 100px #ffca28, 0 0 200px #ffeb3b, inset 0 0 20px #ffeb3b, inset 0 0 40px #ffca28, inset 0 0 60px #ffee58;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ffb300;
  box-shadow: 0 0 6.5px #ffb300, 0 0 40px #ffc107, 0 0 30px #ffca28, 0 0 33px #ffca28, 0 0 65px #ffeb3b, inset 0 0 7px #ffeb3b, inset 0 0 13px #ffca28, inset 0 0 20px #ffee58;
  .main{
  }
}