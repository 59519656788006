.about{
  width: 100%;
  @include tablet{
    margin-top: 100px;
  }
  .about_content{
    width: 80%;
    margin: 0 auto;
    padding: 100px 0;
    @include tablet{
      padding: 0px 20px;
      margin: 100px auto;
      border: 1px solid #B1AEAE;
    }
    .about_content_con{
      &:first-child{
        @include flex(row,center,flex-end);
      }
      &:last-child{
        @include flex(row,center,flex-start);
      }
      flex-wrap: wrap;

      &:first-child,&:last-child{
        @include tablet{
         @include flex(column-reverse,center,center);
        }
      }
    }
    .about_content_text{
      width: calc(60% - 20px);
      &:first-child{
        border: 1px solid $gray;
        border-right: none;
        border-bottom: none;
        box-sizing: border-box;
        padding: 30px 20px 30px 30px;
      }
      &:last-child{
        border: 1px solid $gray;
        border-left: none;
        border-top: none;
        box-sizing: border-box;
        padding: 30px 30px 30px 20px;
      }

      &:first-child,&:last-child{
        @include tablet{
          width: 100%;
          padding: 0;
          border: none;
        }
      }
      font-size: 20px;
      color: $yellow;
      font-weight: 500;
      line-height: 35px;
    }
    .about_content_img{
      width: 40%;
      height: 120%;
      @include tablet{
        width: 100%;
        margin: 20px 0;
      }
      img{
        box-shadow: -1px -1px 20px 10px rgba(197, 196, 196, 0.2), 6px 6px 12px 2px rgba(0, 0, 0, 0.7);
        width: 100%;
      }
      &:last-child{
        @include tablet{
          margin-top: -50px;
        }
      }
      &:first-child{
        margin-left: -5%;
        @include tablet{
          margin-left: 0;
          margin-bottom: -50px;
          box-shadow: -1px -1px 20px 10px rgba(197, 196, 196, 0.2), 6px 6px 12px 2px rgba(0, 0, 0, 0.7);
        }
      }
    }
  }

  .about_workers{
    padding-bottom: 100px;
    .about_workers_title{
      font-size: 20px;
      font-weight: 500;
      color: $yellow;
      margin-bottom: 20px;
      text-align: center;
      text-transform: uppercase;
    }

    .about_workers_con{
      height: 300px;
    }
  }
}

.swiper-button-prev{
  left: -5px !important;
}
.swiper-button-next{
  right: -5px !important;
}