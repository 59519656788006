.projects_desktop{

  @include tablet{
    display: none;
  }
}
.projects{
  // height: 350px;
  width: 100%;
  // overflow: hidden;
  transition: 1;
  // margin-top: 50vh;
  // transform: translate(0% , -50%);

  @include tablet{
    transform: translate((0%, 0%));
    height: 100vh;
    margin-top: 0;

    box-sizing: border-box;
  }

  & {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  &::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
  }
  .section_con{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .section_texts{
    width: 50%;
    height: 350px;
    padding-right:80px;
    box-sizing: border-box;
    @include flex(column, center, flex-start);

    .section_texts_description{
      font-size: 20px;
      line-height: 35px;
      margin-bottom: 32px;
      @include laptop{
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
    .section_texts_name{
      color: $yellow;
      font-size: 36px;
      font-weight: 700;
      @include laptop{
        font-size: 32px;
      }
    }
  }
  .section_bg{
    width: 50%;
    height: 350px;
    object-fit: cover;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.scroll_icon{
  position: fixed;
  bottom:20%;
  @include flex(column);
  span{
    margin-top: 15px;
    color: #B1AEAE;
  }
  @include tablet{
    display: none;
  }
}

.projects_mobile{
  width: 100%;
  height: 100%;
  display: none;
  @include tablet{
    @include flex(row,center);
  }
  .project_cart{
    width: 100%;
    transition: 0.4s;
    @include flex(column,center);
    .project_cart_title{
      font-size: 24px;
      color: $yellow;
      margin-bottom: 30px;
      text-align: center;
      @include mobile{
        font-size: 20px;
        margin-bottom: 24px;
      }
    }
    .project_cart_img{
      width: 100%;
      margin-bottom: 40px;
      @include flex(row,center,center);
      img{
        width: 90%;
        transition: .4s;
      }
    }
    .project_cart_description{
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 35px;
      opacity: 0;
      transition: 0.5s;
      @include mobile{
        font-size: 16px;
      }
    }

    &.active{
      margin-top: -40px;
      img{
        width: 100%;
      }
      .project_cart_description{
        opacity: 1;
      }
    }
  }
  .swiper{
    padding: 50px 0;
  }

  .swiper-pagination{
    position: fixed;
    bottom: 50px !important;
    &-bullet{
      background-color: #B1AEAE;
      opacity: 1;
      transition: 0.3s;

      &-active{
        background-color: $yellow;
        width: 20px;
        border-radius: 5px;
      }
    }
  }
}

.projectsCon{
  width: 100%;
}

.desktopSlider{
  height: 350px;
  margin-top: 50vh;
  transform: translate(0% , -50%);
}