@mixin flex($direction:row, $justify-content:space-between, $align-items:center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin  laptop {
  @media (max-width:1200px){
    @content
  }
};

@mixin  tablet {
  @media (max-width:1000px) {
    @content
  }
};
@mixin  mobile {
  @media (max-width:640px) {
    @content
  }
}