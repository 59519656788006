.service{
  @include flex(color,center,center);
  height: 100vh;
  width: 100%;
  @include tablet{
    height: calc(100vh - 150px);
    margin-top: 150px;
  }

  .service_desktop{
    width: 100%;
    @include flex(row,flex-start,flex-start);
    flex-wrap: wrap;
    @include tablet{
      display: none;
    }
    .service_cart_con{
      width: 100%;
      @include flex(row,flex-start);
      flex-wrap: wrap;
      max-height: 100vh;

      .service_cart{
        position: relative;
        background-color: $black;
        box-shadow: 3px 0px 12px 2px rgba(145, 142, 142, 0.25);
        border-radius: 8px;
        max-width: 295px;
        min-width: 295px;
        height: 265px;
        margin: 0 25px;
        @include flex();
        overflow: hidden;
        cursor: pointer;
        transition: min-width 0.2s,max-width 0.2s, margin-right 0.2s, margin-left 0.2s;
        margin-bottom: 50px !important;

        .main_big_icon{
          img{
            width: 180px;
            object-fit: contain;
          }
        }

        @media (max-width:1420px){
          max-width: 220px;
          min-width: 220px;
          height: 200px;
          margin: 0 25px;
        }
        @include laptop{
          max-width: 200px;
          min-width: 200px;
          height: 170px;
          margin: 0 10px;
        }

        &:hover{
          box-shadow: -1px -1px 20px 10px rgba(197, 196, 196, 0.2), 6px 6px 12px 2px rgba(0, 0, 0, 0.7);
          .service_cart_main .main_small_icon{
            opacity: 1;
          }
        }
        &.active{
          margin-right: -320px;
          @media (max-width:1420px) {
            margin-right: -245px;            
          }
          @include laptop{
            margin-right: -210px;
          }
        }
        &.active, &.active2{
          max-width: 640px;
          min-width: 640px;
          box-shadow: -1px -1px 20px 10px rgba(197, 196, 196, 0.2), 6px 6px 12px 2px rgba(0, 0, 0, 0.7);
          z-index: 1;
          @media (max-width:1420px){
            max-width: 490px;
            min-width: 490px;
          }
          @include laptop{
            max-width: 420px;
            min-width: 420px;
          }
          .service_cart_main .main_small_icon{
            display: none;
          }

          .service_cart_info{
            height: 100%;
            display: flex;
            align-items: center;
            padding-top: 20px;
            padding-bottom: 20px;
            box-sizing: border-box;
            div{
              padding-right: 35px;
              max-height: 100%;
              overflow-y: auto;
              overflow-x: hidden;
            }
            svg{
              opacity: 1;
            }
          }
        }
        &.active2{
          margin-left: -320px;
          @media (max-width:1420px){
            margin-left: -245px;            
          }
          @include laptop{
            margin-left: -210px;
          }
        }
        .service_cart_main{
          min-width: 275px;
          max-width: 275px;
          padding: 50px 10px;
          @include flex(column,center);

          @media (max-width:1420px){
            max-width: 220px;
            min-width: 220px;
            padding: 30px 10px;
            box-sizing: border-box;
          }
          @include laptop{
            max-width: 200px;
            min-width: 200px;
            padding: 20px 5px;
          }

          .main_small_icon{
            position: absolute;
            top: 20px;
            right: 20px;
            opacity: 0;
            transition: 0.3s;
          }
          .main_big_icon{
            margin-bottom: 20px;
            @media (max-width:1420px){
              svg{
                width: 70px;
                height: 70px;
              }
            }
            @include laptop{
              svg{
                width: 50px;
                height: 50px;
              }
            }
            @media (max-width:1420px){
              img{
                width: 70px;
                height: 70px;
              }
            }
            @include laptop{
              img{
                width: 50px;
                height: 50px;
              }
            }
          }
          .main_name{
            text-align: center;
            font-size: 20px;
            font-weight: 500;
            color: $yellow;
            @media (max-width:1420px){
              font-size: 18px;
            }
            @include laptop{
              font-size: 16px;
            }
          }
        }
        .service_cart_info{
          min-width: 345px;
          max-width: 345px;
          font-size: 20px;
          line-height: 35px;

          @media (max-width:1420px){
            max-width: 270px;
            min-width: 270px;     
            line-height: 25px;  
            font-size: 18px;
          }
          @include laptop{
            max-width: 220px;
            min-width: 220px;
            line-height: 25px;
            font-size: 16px;
          }

          svg{
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
            opacity: 0;
          }
        }
      }
    }
  }

  .service_mobile{
    display: none;
    width: 100%;
    @include tablet{
      overflow: auto;
      display: block;
    }
    .service_mobile_cart{
      @include tablet{
        padding-top: 50px;
        height: 100vh;
        overflow: auto;
        box-sizing: border-box;
      }
    }
    .mobile_slider_slide{
      @include flex(column,center,center);

      .cart_title{
        font-size: 24px;
        margin-bottom: 60px;
        font-weight: 500;
        color: $yellow;
        text-align: center;
        
        @include mobile{
          font-size: 20px;
          margin-bottom: 40px;
        }
      }
      .cart_img{
        background: #0A0A0E;
        box-shadow: 6px 6px 12px 2px rgba(0, 0, 0, 0.7), -1px -1px 20px 10px rgba(197, 196, 196, 0.25);
        width: 200px;
        height: 200px;
        border-radius: 50%;
        padding: 20px;
        @include flex(row,center);
        margin: 0 auto;
        box-sizing: border-box;
        @include mobile{
          width: 120px;
          height: 120px;
        }
        img{
          max-width: 80%;
          max-height: 100%;
        }
      }
      .cart_definition{
        margin-top: 40px;
        font-size: 16px;
        font-weight: 500;
        line-height: 30px;
        // margin-bottom: 100%;
        @include mobile{
          margin-top: 40px;
          font-size: 14px;
          font-weight: 500;
          line-height: 25px;
        }
      }
    }

    .swiper-pagination{
      position: fixed;
      bottom: 50px;
      z-index: 2;
      &-bullet{
        background-color: #B1AEAE;
        opacity: 1;
        transition: 0.3s;

        &-active{
          background-color: $yellow;
          width: 20px;
          border-radius: 5px;
        }
      }
    }
  }
}