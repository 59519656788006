 .oneNews{
  width: 100%;
  padding: 50px 0;
  @include tablet{
    margin-top: 100px;
  }
  .oneNews_title{
    font-size: 32px;
    font-weight: 700;
    color: $yellow;
    margin-bottom: 24px;
  }
  .oneNews_date{
    font-size: 16px;
    font-weight: 500;
    opacity: 0.8;
  }

  .oneNews_image{
    margin: 32px auto;
    width: 80%;
    @include tablet{
      width: 100%;
    }
    img,video{
      width: 100%;
      max-height: 400px;
      object-fit: cover;
      @include tablet{
        max-height: fit-content;
      }
    }
    .videoCon{
      position: relative;
      .videoButtons{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .oneNews_definitions{
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 32px;
    text-align: justify;
  }
  .oneNews_images{
    @include flex(row,flex-start);
    flex-wrap: wrap;
    a{
      cursor: pointer;
      width: 32%;
      margin-right: 2%;
      margin-bottom: 32px;
      object-fit: cover;
      &:nth-child(3n){
        margin-right: 0;
      }

      img,video{
        width: 100%;
        object-fit: cover;
        height: 200px;
      }
  
      @include tablet{
        width: 49%;
        &:nth-child(3n){
          margin-right: auto;
        }
        &:nth-child(2n){
          margin-right: 0;
        }
      }
      @include mobile{
        width: 100%;
        margin-right: 0;
      }
    }
  }
 }