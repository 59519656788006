.index{
  height: 100vh;
  position: relative;
  width: 100%;
  .mobile_logo{
    display: none;
    background: radial-gradient(50% 50% at 49.59% 50%, rgba(255, 212, 130, 0.4) 0%, rgba(126, 104, 83, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    backdrop-filter: blur(0.5px);
    width: 140px;
    height: 126px;
    img{
      width: 50px;
      height: 48px;
    }
    
    @include tablet{
      @include flex(row,center);
    }
  }
  .index_name{
    font-size: 20px;
    font-weight: 500;
    color: $yellow;
    position: absolute;
    top: 60px;
    right: 0;
    @include tablet{
      // bottom: 50px !important;
      top: auto;
      right: 50%;
      transform: translate(50%, 0);
      bottom: 100px;
      font-size: 16px;
    }
    @include mobile{
      font-size: 14px;
    }
  }

  .index_main{
    position: absolute;
    top: 50vh;
    transform: translate(0, -50%);
    right: 0;
    @include flex(column,center,flex-end);

    @include tablet{
      width: 80%;
      right: 50%;
      transform: translate(50%, -50%);
      @include flex(column,center,center);
    }
    .index_main_button{
      color: white;
      font-size: 24px;
      font-weight: 500;
      opacity: 1;
      border: 2px solid $yellow;
      border-radius: 32px;
      text-align: center;
      margin-bottom: 50px;
      display: inline-block;
      transition: 3s;
      background-color: black;
      overflow: hidden;
      @include flex(row,center,center);

      @include tablet{
        font-size: 22px;
      }
      @include mobile{
        font-size: 20px;
        width: 100%;
      }
    }

    .index_main_title{
      color: $yellow;
      font-size: 40px;
      line-height: 55px;
      font-weight: 400;
      width: 90%;
      text-align: right;

      @include laptop{
        font-size: 34px;
      }
      @include tablet{
        font-size: 30px;
        text-align: center;
        line-height: 45px;
      }
      @include mobile{
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

//Cube animation
.img-cube{
  width:0;
  height: 50px;
  transform-style: preserve-3d;
  position: relative;
  transform: rotateX(-0deg);
  transition: transform 3s, width 1s;
  @include mobile{
    width: 100%;
    transition: transform 3s, height 1s;
    padding: 10px 0;
  }
}

.front,.bottom, .middle{
  min-width:max-content;
  min-height: max-content;
  position: absolute;
  padding:0 20px;
  background-color:black;
  @include mobile{
    min-width: 100%;
    box-sizing: border-box;
  }

}
.front{
  transform: translateZ(2px);
  background-color: rgb(0, 0, 0);
  @include mobile{
    transform: translateZ(1px);
  }
}
.middle{
  width: 100%;
  height: 100%;
  transform: translateZ(1px);
  background-color: rgb(0, 0, 0);
  @include mobile{
    transform: translateZ(0.5px);
    background-color: black;
  }
}
.bottom{
  transform: rotateX(180deg) translateY(50px);
  transform-origin: 0% 100%;
  background-color: rgb(0, 0, 0);
  @include mobile{
    transform: rotateX(180deg) translateY(100%);
  }
}
.text1,.text2{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mobile{
    height: max-content;
  }
}